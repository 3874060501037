
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';

import { defaultValue } from '@/commons/filters/filters';
import { getValueByProp } from '@/commons/utils/es/object';

@Component({
    name: 'InfoItem'
})
export default class extends Vue {
    @InjectReactive() readonly info!: any;
    @InjectReactive() readonly infoLabelWidth!: any;
    // label标题
    @Prop({ required: true })
    label!: string;
    // label是否有冒号
    @Prop({ default: true })
    colon!: boolean;
    // 属性key
    @Prop({ required: false })
    prop!: string;
    // label 的宽度
    @Prop({ required: false })
    labelWidth!: string;
    // content 的宽度
    @Prop({ required: false })
    contentWidth!: string;

    // 值类型
    @Prop({ default: 'text' })
    type!: string;

    // 值处理
    @Prop({ required: false })
    valueFilter!: (value: string) => string;

    // info item 样式
    @Prop({ required: false })
    itemStyle!: { [key: string]: string }

    // info label 样式名
    @Prop({ required: false })
    labelClassName!: (value: string) => string;
    // info value 样式名
    @Prop({ required: false })
    valueClassName!: (value: string) => string;

    @Prop({ default: '--' })
    private defaultValue!: any;

    // 展示的内容
    public get content(): string | null {
        // 没有默认插槽/父组件没有model情况，直接展示默认文本
        if (this.$slots.default || !this.info || (this.prop === undefined)) {
            return '--';
        }
        // 如果有值过滤器
        if (this.valueFilter) {
            const value: any = getValueByProp(this.info, this.prop, null);
            if (value || value === 0) {
                return defaultValue(this.valueFilter(value));
            } else {
                return '--';
            }
        }
        return defaultValue(getValueByProp(this.info, this.prop, this.defaultValue));
    }

    public get computedItemStyle(): Object {
        return this.itemStyle ? this.itemStyle : {};
    }

    // label的样式
    public get labelStyle(): Object {
        const ret = {};
        const labelWidth = this.labelWidth || this.infoLabelWidth;
        if (labelWidth) {
            // @ts-ignore
            ret.width = labelWidth;
        }
        return ret;
    }

    // content的样式
    public get contentStyle(): Object {
        const ret: { [key: string]: any } = {};
        const contentWidth = this.contentWidth || this.info?.contentWidth;
        if (contentWidth) {
            // @ts-ignore
            ret.width = contentWidth;
        }
        return ret;
    }

    /**
     * 获取 Label样式名
     */
    public get getLabelClass(): string | null {
        if (this.labelClassName) {
            const label: any = getValueByProp(this.info, this.prop, null);
            return this.labelClassName(label);
        } else return '';
    }

    /**
     * 获取 Value样式名
     */
    public get getValueClass(): string | null {
        if (this.valueClassName) {
            const value: any = getValueByProp(this.info, this.prop, null);
            return this.valueClassName(value);
        } else return '';
    }
}
