import 'moment/locale/zh-cn';

import moment from 'moment';

import { Dept } from '@/entities/account/dept';
import { PersonInfo } from '@/entities/account/person';

export { parseTime } from '@/commons/utils/es/time.utils';

// 拼接完整的图片地址
export const getImageUrl = (imageUrl?: string) => imageUrl ? process.env.VUE_APP_PERSON_INFO_OSS_URL + imageUrl : null;

/**
 * 获取默认问题
 */
export const defaultValue = (value: any) => {
    if (!value && value !== 0) {
        return '--';
    } else {
        return value;
    }
};

// form的模式
export const mode = (editingObject: any): string => (editingObject && editingObject.id) ? 'update' : 'create';

export const percentage = (percentage: number): string => percentage + '%';

// 通过code获取text
export const getTextByCode = (code: number | '--', codeMap: Map<number, string>, defaultText: string = '--') => {
    if (code === undefined || code === null || code === '--') {
        return defaultText;
    }
    const text = codeMap.get(code);
    return text || defaultText;
};

// 时间（返回年/月）
export const parseTimeToYearMonth = (time?: string) => {
    if (!time) return '--';
    const date = new Date(time);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return year + '年' + month + '月';
};
// 时间（返回星期）
export const parseWeek = (time?: string, format?: string) => {
    if (!time) return '--';
    if (!format) format = 'YYYY-MM-DD';
    return moment(time).format('dddd');
};
// 时间（返回月/日）
export const parseTimeToMonthDay = (time?: string) => {
    if (!time) return '--';
    const date = new Date(time);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return month + '月' + day + '日';
};

/**
 * 表单弹框的标题
 */
export const title = (mode: 'create' | 'update', entityName: string) => mode === 'create'
    ? `新增${entityName}` : `编辑${entityName}`;

// 给部门的数据做处理
export const getPersonDepts = (personInfo: PersonInfo): Dept[][] => {
    // 判断人员（教职工/学生）信息有没有
    if (!personInfo) {
        return [];
    }
    // 获取教职工部门树
    const deptTree: Dept[][] = personInfo.deptTree || [];
    if (deptTree.length > 1) {
        // 1.在所有部门中 - 拿到主部门的数据
        // @ts-ignore
        const depts: Dept[] = deptTree.find((item: Dept[]) => item.some((dept: Dept) => dept.id === personInfo.person.mainDeptId));
        // 2.在所有部门中 - 删除主部门的数据
        deptTree.map((item: Dept[], index: number) => {
            if (item === depts) {
                deptTree.splice(index, 1);
            }
        });
        // 3.把主部门 的数据添加到数组的开头
        deptTree.unshift(depts);
        return deptTree;
    } else {
        // 只有一个部门 - 默认为主部门(不做任何处理)
        return deptTree;
    }
};

// 拼接部门单元格内容
export const getDepartmentsListText = (row: any) => {
    let listText: string = '';
    const deptTree = getPersonDepts(JSON.parse(JSON.stringify(row)));
    deptTree.forEach((item: any, index: number) => {
        let text: string = '';
        if (item) {
            item.forEach((deptValue: any, i: number) => {
                text += deptValue.name;
                if (i < item.length - 1) {
                    text += ' / ';
                }
            });
            // text += ' ; ';
            listText = text + listText;
        }
    });
    if (deptTree.length === 0) {
        return listText = '--';
    }
    return listText;
};

// 文档图标
export const getDocumentIconName = (name: string) => {
    const splitTemp = name?.split('.');
    const fileSuffix = splitTemp[splitTemp!.length - 1];
    if (splitTemp.length <= 1) return 'document-folder';
    let iconName = 'document-unknown';
    switch (fileSuffix) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'svg':
        iconName = 'document-image';
        break;
    case 'xlsx':
    case 'xls':
    case 'csv':
    case 'xltm':
        iconName = 'document-excel';
        break;
    case 'pdf':
        iconName = 'document-pdf';
        break;
    case 'ppt':
    case 'pptx':
        iconName = 'document-ppt';
        break;
    case 'txt':
        iconName = 'document-txt';
        break;
    case 'doc':
    case 'docx':
        iconName = 'document-word';
        break;
    case 'zip':
    case 'rar':
    case '7z':
    case 'tar':
        iconName = 'document-zip';
        break;
    }
    return iconName;
};
