import { Message } from 'element-ui';
// @ts-ignore
import { isVNode } from 'element-ui/src/utils/vdom';
import { ElMessageOptions, MessageType } from 'element-ui/types/message';
import _ from 'lodash';

const messageTypes: MessageType[] = ['success', 'warning', 'info', 'error'];
messageTypes.forEach(type => {
    // @ts-ignore
    Message[type] = (options: ElMessageOptions | string) => {
        if (_.isObject(options) && !isVNode(options)) {
            return Message({
                ...options,
                showClose: true,
                duration: 2000,
                type
            });
        }
        return Message({
            type,
            showClose: true,
            duration: 2000,
            message: options as string
        });
    };
});
