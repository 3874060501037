import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * 弹框底部
 */
@Component({ name: 'DialogFooter' })
export default class extends Vue {
    /**
     * 加载中
     */
    @Prop({ default: false }) public loading!: boolean;
    /**
     * 是否禁用
     */
    @Prop({ default: false }) public disabled!: boolean;
}
