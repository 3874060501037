
import { Tooltip } from 'element-ui';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

@Component({
    name: 'TableBtn'
})
export default class extends Vue {
    /**
     * hover 显示内容
     */
    @Prop({ required: true }) private content!: string;
    /**
     * 标签
     */
    @Prop({ required: true }) private icon!: string;
    /**
     * 是否禁用
     */
    @Prop({ default: false }) private disabled!: boolean;

    @Ref() elTooltip!: Tooltip;

    clickHandler() {
        this.disabled || this.$emit('click');
    }

    cancelFocus() {
        // if (this.elTooltip && this.elTooltip) {
        //     this.$nextTick(() => {
        //         console.log(this.elTooltip.focusing);
        //         this.elTooltip.focusing = false;
        //     });
        // }
    }
}
