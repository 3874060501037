var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-pagination",
    _vm._g(
      {
        attrs: {
          "current-page": _vm.currentPage,
          "page-sizes": [10, 20, 30, 40],
          "page-size": _vm.pageData ? _vm.pageData.pageSize : null,
          "pager-count": 5,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.pageData ? _vm.pageData.total : null,
        },
        on: { "update:current-page": _vm.updateCurrentPage },
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }