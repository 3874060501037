import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * 页面
 */
@Component({ name: 'PageContent' })
export default class extends Vue {
    @Prop({ default: false }) public loading!: boolean;
    @Prop({ default: false }) public title!: string;
    @Prop({ required: false }) public grade!: string;

    private get headerTitle(): string {
        return this.title || this.$route.meta.title;
    }
}
