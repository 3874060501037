
import { Component, Prop, ProvideReactive, Vue, Watch } from 'vue-property-decorator';

@Component({ name: 'Info', inject: [] })
export default class extends Vue {
    // 数据源
    @Prop({ required: true })
    public model!: Object;

    @ProvideReactive() info: any = null;
    @ProvideReactive() infoLabelWidth: any = null;

    // label的宽度
    @Prop({ required: false })
    public labelWidth!: string;
    name = 'hello';

    @Watch('model', { deep: true, immediate: true })
    setInfo() {
        this.info = this.model;
    }

    @Watch('labelWidth', { immediate: true })
    setLabelWidth() {
        this.infoLabelWidth = this.labelWidth;
    }
}
