import Cookies from 'js-cookie';

// App
const sidebarStatusKey = `tfpm_admin_${process.env.VUE_APP_ENV}_sidebar_status`;
export const getSidebarStatus = () => Cookies.get(sidebarStatusKey);
export const setSidebarStatus = (sidebarStatus: string) => Cookies.set(sidebarStatusKey, sidebarStatus);

// User
const tokenKey = `tfpm_admin_${process.env.VUE_APP_ENV}_access_token`;
export const getToken = () => Cookies.get(tokenKey);
export const setToken = (token: string) => Cookies.set(tokenKey, token);
export const removeToken = () => Cookies.remove(tokenKey);

// import service name
const importServiceNameKey = `${process.env.VUE_APP_AUTH_USERNAME}_admin_${process.env.VUE_APP_ENV}_import_service_name`;
export const getImportServiceName = () => Cookies.get(importServiceNameKey);
export const setImportServiceName = (name: string) => Cookies.set(importServiceNameKey, name);
export const removeImportServiceName = () => Cookies.remove(importServiceNameKey);

const loginErrorTimesKey = `tfpm_admin_admin_${process.env.VUE_APP_ENV}_login_times`;
export const getLoginErrorTimes = () => Cookies.get(loginErrorTimesKey);
export const setLoginErrorTimes = (loginTimes: string) => Cookies.set(loginErrorTimesKey, loginTimes);
export const clearLoginErrorTimes = () => Cookies.remove(loginErrorTimesKey);
