var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "cc-image" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.src,
              expression: "!src",
            },
          ],
          staticClass: "no-image",
        },
        [
          _c("img", {
            staticClass: "empty-image",
            attrs: {
              src: require("../../../../assets/cc-image/empty.png"),
              alt: "",
            },
          }),
        ]
      ),
      _vm.src
        ? _c(
            "el-image",
            _vm._b(
              {
                staticClass: "el-image",
                attrs: { fit: "cover", src: _vm.src },
              },
              "el-image",
              _vm.$attrs,
              false
            ),
            [
              _c(
                "div",
                {
                  staticClass: "loading",
                  attrs: { slot: "placeholder" },
                  slot: "placeholder",
                },
                [
                  _c("svg-icon", {
                    staticClass: "loading-icon",
                    attrs: { name: "loading" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "error",
                  attrs: { slot: "error" },
                  slot: "error",
                },
                [
                  _c("img", {
                    staticClass: "error-image",
                    attrs: {
                      src: require("../../../../assets/cc-image/error.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }