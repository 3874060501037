import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class createPersonHeader extends Vue {
    @Prop({ required: false }) readonly title!: string;

    private get headerTitle(): string {
        return this.title || this.$route.meta.title;
    }
}
