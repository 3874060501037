import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * 图片
 */
@Component({ name: 'CcImage' })
export default class extends Vue {
    /**
     * 图片路径
     */
    @Prop({ required: true }) public src!: string;

    /**
     * 加载中
     */
    public loading: boolean = true;
}
