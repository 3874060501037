
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'FormPage'
})
export default class extends Vue {
    @Prop({
        default: true
    })
    private buttonVisible!: boolean;
}
