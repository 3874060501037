import { RouteConfig } from 'vue-router';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { asyncRoutes, constantRoutes } from '@/router';
import store from '@/store';

/**
 * 判断路由是否拥有权限
 * @param permissions 用户拥有的权限
 * @param route
 */
const hasPermission = (permissions: string[], route: RouteConfig): boolean => {
    if (route.meta && route.meta.permissions) {
        return route.meta.permissions.some((el: string) => permissions.includes(el));
    } else {
        return true;
    }
};

// 设置路由重定向
const setRedirectRoute = (route: RouteConfig) => {
    const childrenRoutes: RouteConfig[] = route.children || [];
    if (!childrenRoutes.length) return;
    route.redirect = { name: childrenRoutes[0].name };
    childrenRoutes.forEach(setRedirectRoute);
};

export const filterAsyncRoutes = (routes: RouteConfig[], permissions: string[]): RouteConfig[] => {
    const res: RouteConfig[] = [];
    routes.forEach(route => {
        const r = { ...route };
        if (hasPermission(permissions, r)) {
            if (r.children) {
                r.children = filterAsyncRoutes(r.children, permissions);
                setRedirectRoute(r);
            }
            if (!r.children || r.children.length) {
                res.push(r);
            }
        }
    });
    return res;
};

export interface IPermissionState {
    routes: RouteConfig[]
    dynamicRoutes: RouteConfig[]
}

@Module({ dynamic: true, store, name: 'permission' })
class Permission extends VuexModule implements IPermissionState {
    public routes: RouteConfig[] = [];
    public dynamicRoutes: RouteConfig[] = [];
    public generated: boolean = false;

    @Mutation
    private SET_ROUTES(routes: RouteConfig[]) {
        this.routes = constantRoutes.concat(routes);
        this.dynamicRoutes = routes;
    }

    @Mutation
    public SET_GENERATED(generated: boolean) {
        this.generated = generated;
    }

    @Action({ rawError: true })
    public GenerateRoutes(permissions: string[]) {
        const accessedRoutes = filterAsyncRoutes(asyncRoutes, permissions);
        const indexPage = {
            path: '/',
            name: 'index',
            redirect: { name: accessedRoutes[0].name }
        };
        this.SET_ROUTES([indexPage, ...accessedRoutes]);
        this.SET_GENERATED(true);
    }
}

export const PermissionModule = getModule(Permission);
