var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "list-page-container" }, [
    _c("div", { staticClass: "table-pagination-wrapper" }, [
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "search" }, [_vm._t("search")], 2),
      ]),
      _vm.divider ? _c("div", { staticClass: "hr" }) : _vm._e(),
      _c(
        "div",
        { staticClass: "table-header" },
        [
          _c(
            "div",
            { staticClass: "table-header__title" },
            [
              _vm._t("title", function () {
                return [_vm._v(" " + _vm._s(_vm.title) + " ")]
              }),
            ],
            2
          ),
          _vm._t("operation"),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "table-pagination" },
        [
          _c(
            "base-table",
            _vm._g(
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "table",
                attrs: {
                  stripe: "",
                  "header-cell-class-name": "table-header-cell",
                  data: _vm.tableData,
                  border: "",
                },
              },
              _vm.$listeners
            ),
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }