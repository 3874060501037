/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-up': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 5.667l1.167 1.166L4.999 3l3.833 3.833L10 5.667l-5-5z" _fill="#000" fill-rule="evenodd" fill-opacity=".65"/>'
  }
})
