var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "image-picker",
      class: _vm.required && "required",
      style: _vm.imgStyle,
    },
    [
      _vm._l(_vm.showImageUrls, function (url, index) {
        return _c(
          "div",
          { key: url, staticClass: "img-container", style: _vm.imgStyle },
          [
            _c("el-image", {
              ref: "image",
              refInFor: true,
              staticClass: "photo",
              attrs: {
                slot: "image",
                src: url,
                "preview-src-list": _vm.showImageUrls,
                fit: "cover",
                alt: "",
              },
              slot: "image",
            }),
            _vm.tip
              ? _c("div", { staticClass: "hover-tip" }, [
                  _c("span", { staticClass: "hover-tip__text" }, [
                    _vm._v(_vm._s(_vm.tip)),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "img-hover" },
              [
                _c("svg-icon", {
                  staticClass: "mr-16",
                  attrs: { name: "eye", original: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.showImage(index)
                    },
                  },
                }),
                _c("svg-icon", {
                  attrs: { name: "upload", original: "" },
                  on: {
                    click: function ($event) {
                      _vm.countLimit === 1 && _vm.handlePhotoClick()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c("input", {
        ref: "imagePicker",
        staticStyle: { display: "none" },
        attrs: {
          type: "file",
          accept: "image/png, image/jpeg, image/png, image/bmp",
        },
        on: { change: _vm.handlePhotoChange },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showImageUrls.length < _vm.countLimit,
              expression: "showImageUrls.length < countLimit",
            },
          ],
          staticClass: "placeholder",
          style: _vm.imgStyle,
          on: {
            click: function ($event) {
              return _vm.handlePhotoClick()
            },
          },
        },
        [
          _vm._t("placeholder", function () {
            return [
              _c(
                "div",
                { staticClass: "svg-wrapper" },
                [_c("svg-icon", { attrs: { name: "edit" } })],
                1
              ),
              _c("div", { staticClass: "tip" }, [_vm._v(" 建议60*60像素 ")]),
            ]
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errorState,
              expression: "errorState",
            },
          ],
          staticClass: "error-text",
        },
        [_vm._v(" 请选择照片 ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }