import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

// 弹框文本
@Component({ name: 'EllipsisText' })
export default class extends Vue {
    // 文本
    @Prop({ required: true }) value: string;
    // 行数
    @Prop({ default: 1 }) row: number;

    @Ref() text: HTMLDivElement;

    // 是否展示悬浮
    isShowHover: boolean = false;

    textStyle: any = {}

    div: any = null;

    @Watch('value')
    setStyle() {
        this.$nextTick(() => {
            this.getStyle();
        });
    }

    getStyle() {
        const { row } = this;
        const lineHeight = Number(getComputedStyle(this.text).lineHeight.replace('px', ''));
        const height = Number(getComputedStyle(this.text).height.replace('px', ''));
        if (height > lineHeight * row) {
            this.isShowHover = true;
            this.textStyle = {
                height: `${lineHeight * row}px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                webkitLineClamp: row,
                webkitBoxOrient: 'vertical',
                cursor: 'pointer'
            };
        } else {
            this.isShowHover = false;
            this.textStyle = {
                cursor: 'text'
            };
        }
    }

    init() {
        this.div = document.querySelector('.hover-wrap');
        if (!this.div && this.row) {
            this.div = document.createElement('div');
            this.div.className = 'hover-wrap';
            this.div.style.top = 0;
            this.div.style.left = 0;
            document.body.append(this.div);
        }
        if (this.div && this.row) {
            this.getStyle();
        }
    }

    mouseenter(e: any) {
        if (!this.isShowHover) {
            return;
        }
        const box = e.target.getBoundingClientRect();
        const top = box.top - 16 + 'px';
        let left: string | number = 0;
        this.div.innerHTML = this.value;
        left =
            box.left +
            (box.width - this.div.getBoundingClientRect().width) / 2 +
            'px';
        this.div.style.top = top;
        this.div.style.left = left;
        this.div.classList.add('active', 'el-tooltip__popper', 'is-dark');
    }
    mouseleave(e: any) {
        if (!this.isShowHover) {
            return;
        }
        if (e.relatedTarget !== this.div) {
            this.div.style.top = 0;
            this.div.style.left = 0;
            this.div.classList.remove('active');
        }
        this.div.onmouseleave = () => {
            this.div.style.top = 0;
            this.div.style.left = 0;
            this.div.classList.remove('active');
        };
    }

    mounted() {
        this.init();
    }
}
