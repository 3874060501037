import './element';

import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
// @ts-ignore
import VueDragResize from 'vue-drag-resize';
// @ts-ignore
import VueKindEditor from 'vue-kindeditor';

// @ts-ignore
import {
    BaseTable,
    ButtonsWrapper,
    CcCol,
    CcDialog,
    CcDialogFooter,
    CcFormPage,
    CcFormPageHeader,
    CcHighlightKeyword,
    CcImage,
    CcInfo,
    CcInfoItem, CcInfoPageContent,
    CcListPage,
    CcPage,
    CcPageContent, CcRow,
    CcSelect,
    CcTable,
    CcTips,
    HeaderCol,
    HeaderOperation,
    ImagePicker,
    ListHeader,
    NewHeaderCol, NewListHeader, PageFooter,
    PageHeader,
    Pagination,
    TableBtn,
    TablePagination
} from '@/commons/components/cc';
import { EllipsisText } from '@/commons/components/cc/ellipsis.text';

Vue.component('CcPagination', Pagination);
Vue.component('CcTablePagination', TablePagination);
Vue.component('CcTableBtn', TableBtn);
Vue.component('CcImage', CcImage);
Vue.component('CcPageContent', CcPageContent);
Vue.component('CcInfoPageContent', CcInfoPageContent);
Vue.component('CcPage', CcPage);
Vue.component('CcPageFooter', PageFooter);
Vue.component('CcImagePicker', ImagePicker);
Vue.component('CcInfo', CcInfo);
Vue.component('CcInfoItem', CcInfoItem);
Vue.component('NewHeaderCol', HeaderCol);
Vue.component('CcHeaderOperation', HeaderOperation);
Vue.component('CcListHeader', ListHeader);
Vue.component('CcCol', CcCol);
Vue.component('CcHighlightKeyword', CcHighlightKeyword);
Vue.component('CcHeaderCol', HeaderCol);
Vue.component('CcHeaderOperation', HeaderOperation);
Vue.component('CcListPage', CcListPage);
Vue.component('CcFormPageHeader', CcFormPageHeader);
Vue.component('CcFormPage', CcFormPage);
Vue.component('CcRow', CcRow);
Vue.component('CcDialog', CcDialog);
Vue.component('ButtonsWrapper', ButtonsWrapper);
Vue.component('CcDialogFooter', CcDialogFooter);
Vue.component('CcTips', CcTips);
Vue.component('NewListHeader', NewListHeader);
Vue.component('NewHeaderCol', NewHeaderCol);
Vue.component('BaseTable', BaseTable);
Vue.component('CcTable', CcTable);
Vue.component('CcSelect', CcSelect);
Vue.component('PageHeader', PageHeader);
Vue.component('vue-drag-resize', VueDragResize);
Vue.component('EllipsisText', EllipsisText);
Vue.use(VueKindEditor);
Vue.use(VueClipboard);
