/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sjzg': {
    width: 44,
    height: 44,
    viewBox: '0 0 44 44',
    data: '<path pid="0" d="M36 44a4 4 0 01-4-4V12a4 4 0 014-4h4a4 4 0 014 4v28a4.005 4.005 0 01-4 4zm0-4h4V12h-4zm-16 4a4 4 0 01-4-4V4a4 4 0 014-4h4a4 4 0 014 4v36a4 4 0 01-4 4zm0-40v36h4V4h-4zM4 44a4 4 0 01-4-4V24a4 4 0 014-4h4a4 4 0 014 4v16a4 4 0 01-4 4zm0-4h4V24H4z" _fill="#36c7b5"/>'
  }
})
