import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'CcDialog' })
export default class extends Vue {
    /**
     * 标题
     */
    @Prop({ required: true }) public title!: string;
    /**
     * 点击弹框蒙层是否关闭
     */
    @Prop({ default: false }) public closeOnClickModal!: boolean
    /**
     * 点击弹框关闭事件
     */
    @Prop() public close?: any;
}
