var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "info-item", style: _vm.computedItemStyle }, [
    _c(
      "span",
      {
        staticClass: "info-item__label",
        class: _vm.getLabelClass,
        style: _vm.labelStyle,
      },
      [
        _vm._v(_vm._s(_vm.label) + " "),
        _c("span", { staticClass: "colon" }, [
          _vm._v(_vm._s(_vm.colon ? "：" : "")),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "info-item__value",
        class: _vm.getValueClass,
        style: _vm.contentStyle,
      },
      [
        _vm._t(
          "default",
          function () {
            return [_vm._v(" " + _vm._s(_vm.content) + " ")]
          },
          { content: _vm.content }
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }