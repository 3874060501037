/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'zhpt-four': {
    width: 36,
    height: 44,
    viewBox: '0 0 36 44',
    data: '<path pid="0" d="M17.582 43.956a27.259 27.259 0 01-10.935-5.547C2.3 34.8 0 30.506 0 26V10a2 2 0 011.105-1.789l16-8a2 2 0 011.789 0l16 8A2 2 0 0136 10v16c0 4.506-2.3 8.8-6.647 12.409a27.259 27.259 0 01-10.935 5.547 2 2 0 01-.835 0zM4 11.237V26c0 4.142 2.83 7.36 5.2 9.332a23.786 23.786 0 008.8 4.615 23.786 23.786 0 008.8-4.614C29.171 33.36 32 30.142 32 26V11.237l-14-7zM16 30v-6h-6a2 2 0 010-4h6v-6a2 2 0 114 0v6h6a2 2 0 010 4h-6v6a2 2 0 11-4 0z" _fill="#36c7b5"/>'
  }
})
