/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ddgl': {
    width: 36,
    height: 44,
    viewBox: '0 0 36 44',
    data: '<path pid="0" d="M6 44a6.006 6.006 0 01-6-6V8a6.006 6.006 0 016-6 2 2 0 010 4 2 2 0 00-2 2v30a2 2 0 002 2h24a2 2 0 002-2V8a2 2 0 00-2-2 2 2 0 010-4 6.006 6.006 0 016 6v30a6.006 6.006 0 01-6 6zm4-10a2 2 0 010-4h16a2 2 0 010 4zm0-8a2 2 0 010-4h16a2 2 0 010 4zm0-8a2 2 0 010-4h16a2 2 0 110 4zm4-8a4 4 0 01-4-4V4a4 4 0 014-4h8a4 4 0 014 4v2a4 4 0 01-4 4zm0-6v2h8V4h-8z" _fill="#36c7b5"/>'
  }
})
