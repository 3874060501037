
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'LXPagination' })
export default class extends Vue {
    /**
     * 当前页
     */
    @Prop({ required: true })
    private currentPage!: any;
    /**
     * 分页数据
     */
    @Prop({ required: true })
    private pageData!: any;

    /**
     * 更新currentPage
     */
    private updateCurrentPage(value: any) {
        this.$emit('update:current-page', value);
    }
}
