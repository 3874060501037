import { mixins } from 'vue-class-component';
import { noop } from 'vue-class-component/lib/util';
import { Component, Prop, Watch } from 'vue-property-decorator';

import Emitter from '@/mixins/emitter';

/**
 * 图片选择器
 */
@Component({ name: 'ImagePicker' })
export default class extends mixins(Emitter) {
    public $refs!: { imagePicker: HTMLInputElement };
    @Prop({ default: false }) public multiple!: boolean;

    @Prop({ default: '' }) public tip!: string;
    /**
     * 父组件传入的地址
     */
    // @ts-ignore
    @Prop({ default: () => [] }) public urls!: string[];

    /**
     * 图片数量限制
     */
    @Prop({ required: false, default: 1 }) public readonly countLimit!: number;

    @Prop({ default: false }) public required!: boolean;
    @Prop({ default: '140px' }) public imgWidth!: string;
    @Prop({ default: '140px' }) public imgHeight!: string;

    @Prop({ type: String }) public prop!: string;
    /**
     * 展示图片的地址
     */
    public showImageUrls: string[] = [];
    public errorState: boolean = false;
    public photos: File[] = [];

    public get imgStyle(): Object {
        const sty: any = {};
        this.imgHeight && (sty.height = this.imgHeight);
        this.imgWidth && (sty.width = this.imgWidth);
        return sty;
    }

    /**
     * 选择照片
     */
    public handlePhotoClick() {
        this.$refs.imagePicker.value = '';
        this.$refs.imagePicker.click();
    }

    /**
     * 照片更新
     */
    public async handlePhotoChange(e: any) {
        const files: FileList | null = e.target!.files;
        if (!files) {
            return;
        }
        this.errorState = false;
        this.photos = Array.from(e.target.files);
        this.showImageUrls = this.photos.map(photo => URL.createObjectURL(photo));
        this.$emit('change', this.photos);
    }

    public showImage(index: number) {
        // @ts-ignore
        this.$refs[`image`][index].clickHandler();
    }

    /**
     * 校验
     */
    public validate(trigger: string, callback: Function = noop): boolean {
        const passed: boolean = this.showImageUrls.length === this.countLimit;
        if (passed) {
            callback();
            return true;
        } else {
            this.errorState = true;
            callback(`图片数量少于${this.countLimit}`, [this.prop]);
            return false;
        }
    }

    @Watch('urls', { immediate: true })
    public syncShowImageUrls(value: string[]) {
        this.showImageUrls = value.map(url => this.$utils.getCompleteUrl(url));
        this.photos = [];
    }

    public mounted() {
        if (this.prop) {
            this.dispatch('ElForm', 'el.form.addField', [this]);
        }
    }
}
