enum LABEL_WIDTH_CONSTANT {
    word1 = '30px',
    word2 = '42px',
    word3 = '56px',
    word4 = '70px',
    word5 = '84px',
    word6 = '98px',
    word7 = '112px',
    word8 = '126px',
    word9 = '148px'
}

export enum MULTI_SELECT_WIDTH {
    word1 = '222px',
    word2 = '234px',
    word3 = '248px',
    word4 = '262PX',
    word5 = '276px',
    word6 = '290px',
    word7 = '304px',
    word8 = '318px',
    word9 = '340px'
}

export default LABEL_WIDTH_CONSTANT;

function * generate() {
    let width = 10 + 24 + 16;
    while (true) {
        width = width + 32;
        yield `${width}px`;
    }
}

export enum TABLE_CELL_WIDTH {
    WORD1 = `42px`,
    WORD2 = `56px`,
    WORD3 = `70px`,
    WORD4 = `84px`,
    WORD5 = `98px`,
    WORD6 = `112px`,
    WORD7 = `126px`,
    WORD8 = `140px`,
    WORD9 = `154px`,
    WORD10 = `168px`,
    WORD11 = `182px`,
    WORD12 = `196px`,
    WORD13 = `210px`,
    WORD14 = `224px`,
    WORD15 = `238px`,
    WORD16 = `252px`,
    BUTTON1 = '50px',
    BUTTON2 = '82px',
    BUTTON3 = '114px',
    BUTTON4 = '146px',
    BUTTON5 = '178px',
    BUTTON6 = '210px',
}
