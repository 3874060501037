import moment from 'moment';

import {
    defaultValue,
    getDepartmentsListText,
    getDocumentIconName,
    getPersonDepts,
    getTextByCode
} from '@/commons/filters/filters';
import { downloadFile } from '@/commons/utils/index';
import CELL_WIDTH from '@/meta/cell.width';
import LABEL_WIDTH_CONSTANT, { MULTI_SELECT_WIDTH, TABLE_CELL_WIDTH } from '@/meta/label.width';
import { UserModule } from '@/store/modules/user';

import { convertObjectToFormData, getValueByProp } from './es/object';
import { parseTime } from './es/time.utils';

const getCompleteUrl = (url: string) => {
    return url && process.env.VUE_APP_OSS_URL + url;
};

const openOfficePreview = (url: string, router: any) => {
    if (!url) return false;
    const routeUrl = router.resolve({
        path: '/document-preview',
        query: { url }
    });
    window.open(window.location.origin + routeUrl.href, '_blank');
};

// 格式化时间
const formatTime = (time: string, format?: string) => {
    if (!format) format = 'YYYY-MM-DD HH:mm:ss';
    if (!time) return '--';
    return moment(time).isValid() ? moment(time).format(format) : '--';
};
// 两个时间相隔多少天
export function diffDays(createTime: string) {
    if (!createTime) return 0;
    const current = moment().format('YYYY-MM-DD');
    const time = moment(createTime).format('YYYY-MM-DD');
    return moment(current).diff(time, 'days');
}

// 金额
export function formatMoney(transactionAmount: number | null): number {
    if (transactionAmount) {
        return Number((transactionAmount / 100).toFixed(2));
    } else {
        return 0;
    }
}

const utils = {
    get: getValueByProp,
    getValueByProp,
    convertObjectToFormData,
    parseTime,
    getTextByCode,
    defaultValue,
    getCompleteUrl,
    getPersonDepts,
    getDepartmentsListText,
    getDocumentIconName,
    formatTime,
    isAdmin: UserModule.IsAdmin,
    MULTI_SELECT_WIDTH,
    LABEL_WIDTH_CONSTANT,
    CELL_WIDTH,
    diffDays,
    downloadFile,
    TABLE_CELL_WIDTH,
    formatMoney,
    openOfficePreview
};

export type Utils = typeof utils;

export default utils;
