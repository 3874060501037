import { Table } from 'element-ui';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

import { ZINDEX } from '@/meta/zindex';

import Pagination from './pagination/pagination.vue';

@Component({ name: 'TablePagination', components: { CcPagination: Pagination } })
export default class extends Vue {
    ZINDEX = ZINDEX;
    /**
     * 表格数据
     */
    @Prop({ required: true })private tableData!: any[];
    /**
     * 表格标题
     */
    @Prop({ required: false }) private title!: string;
    /**
     * 请求数据中
     */
    @Prop({ required: true })private loading!: boolean;
    /**
     * 当前页
     */
    @Prop({ required: true })private currentPage!: any;
    /**
     * 分页数据
     */
    @Prop({ required: true })private pageData!: any;
    /**
     * 改变页/数量
     */
    @Prop({ required: true })private changeSize!: any;
    /**
     * 改变页
     */
    @Prop({ required: false })private changePage!: any;
    @Prop({ default: false })private selection!: boolean;

    @Ref() elTable!: Table;

    checkAll: boolean = false

    indeterminate: boolean = false;

    // 设置是否全选
    handleSelectChange(selection: any[]) {
        this.checkAll = selection.length === this.tableData.length;
        this.indeterminate = !!(selection.length && (selection.length !== this.tableData.length));
    }

    handleCheckAllChange(selectAll: boolean) {
        this.elTable.toggleAllSelection();
    }
}
