import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';

import Layout from '@/layout/index.vue';
import { EmptyRoute } from '@/layout/side.layout';
import { ROUTER } from '@/meta/user';

Vue.use(Router);

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    permissions                  Routing permissions
  }
*/

export const constantRoutes: RouteConfig[] = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/index.vue'),
        meta: { hidden: true, }
    },
    {
        path: '/404',
        component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
        meta: { hidden: true, title: '404' }
    }
];

export const asyncRoutes: RouteConfig[] = [];

const createRouter = () => new Router({
    // mode: 'history',  // Disabled due to Github Pages doesn't support this, enable this if you need.
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes
});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter();
    (router as any).matcher = (newRouter as any).matcher; // reset router
}

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.replace;
Router.prototype.replace = function replace(location: any) {
    // @ts-ignore
    return originalPush.call(this, location).catch((err: any) => err);
};

export default router;
