/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dyfx': {
    width: 44,
    height: 44,
    viewBox: '0 0 44 44',
    data: '<path pid="0" d="M13.436 42.27a22.011 22.011 0 011.793-41.208 2 2 0 111.23 3.806 18 18 0 1022.631 22.8 2 2 0 013.8 1.258A22.021 22.021 0 0113.436 42.27zM22 24a2 2 0 01-2-2V2a2 2 0 012-2 21.984 21.984 0 0122 22 2 2 0 01-2 2zm2-4h15.89A18.036 18.036 0 0024 4.11z" _fill="#36c7b5"/>'
  }
})
