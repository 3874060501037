/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'adress': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M1.818 40a1.818 1.818 0 010-3.637h1.818V5.455A5.461 5.461 0 019.091 0H20a5.461 5.461 0 015.455 5.455v5.454h5.455a5.46 5.46 0 015.454 5.455v20h1.819a1.818 1.818 0 010 3.637zm30.909-3.637v-20a1.82 1.82 0 00-1.818-1.818h-5.455v21.818zm-10.909 0V5.455A1.821 1.821 0 0020 3.636H9.091a1.82 1.82 0 00-1.818 1.819v30.908zm-10.91-5.454a1.818 1.818 0 010-3.637h7.273a1.818 1.818 0 010 3.637zm0-9.091a1.818 1.818 0 010-3.637h7.273a1.818 1.818 0 010 3.637zm0-9.091a1.818 1.818 0 010-3.637h7.273a1.818 1.818 0 010 3.637z" _fill="#fff"/>'
  }
})
