var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "info-page-content" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _vm._t("button"),
        _c("div", { staticClass: "line" }),
        _c("span", { staticClass: "header__title" }, [
          _vm._v(" " + _vm._s(_vm.headerTitle) + " "),
        ]),
        _vm._t("state"),
        _vm._t("title"),
      ],
      2
    ),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "left-content",
        },
        [_vm._t("leftContent")],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "right-content",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "firstPart",
            },
            [_vm._t("rightContentFirstPart")],
            2
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "secondPart",
            },
            [_vm._t("rightContentSecond")],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }