/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'select-down': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 10.5L6.165 6.667 5 7.833l5 5.001 5-5-1.168-1.167z" _fill="#000" fill-rule="evenodd" fill-opacity=".65"/>'
  }
})
