/* tslint:disable */
import './adress'
import './arrow-up'
import './cwgl'
import './ddgl'
import './dtxs'
import './dyfx'
import './email'
import './sbgl'
import './select-down'
import './sjtb'
import './sjzg'
import './smile'
import './top'
import './yygl'
import './zhpt-four'
import './zhpt-one'
import './zhpt-three'
import './zhpt-two'
