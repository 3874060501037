import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'CcHighlightKeyword'
})
export default class extends Vue {
    /**
     * 关键词
     */
    @Prop({ required: true }) public keyword: string | undefined;

    public text: string = '';

    public created() {
        if (this.keyword && this.$slots.default && this.$slots.default.length) {
            const originalText = this.$slots.default[0].text;
            if (originalText) {
                const newText = originalText.replace(new RegExp(this.keyword, 'g'), `<span class="highlight">${this.keyword}</span>`);
                this.text = newText;
            }
        }
    }
}
