/**
 * 将对象转化为formData
 * @param object
 */
// export function convertObjectToFormData(object: { [key: string]: any}): FormData {
//     const formData = new FormData();
//     for (const key of Object.keys(object)) {
//         if (object[key] || object[key] === 0) {
//             if (Array.isArray(object[key])) {
//                 // eslint-disable-next-line no-proto
//                 if (object[key][0] && object[key][0].__proto__.constructor.toString().search('File') > 0) {
//                     object[key].forEach((value: any) => {
//                         formData.append(key, value);
//                     });
//                 } else {
//                     formData.append(key, object[key]);
//                 }
//             } else {
//                 formData.append(key, object[key]);
//             }
//         }
//     }
//     return formData;
// }

/**
 * 将对象转化为formData
 * @param object
 */
/**
 * 将对象转化为formData
 * @param object
 */
import _ from 'lodash';

export function convertObjectToFormData(object: { [key: string]: any }): FormData {
    const formData = new FormData();
    for (const key of Object.keys(object)) {
        if (object[key] || object[key] === 0) {
            if (Array.isArray(object[key]) && ['[object File]', '[object Blob]'].includes(Object.prototype.toString.call(object[key][0]))) {
                object[key].forEach((value: any) => {
                    formData.append(key, value);
                });
            } else {
                formData.append(key, object[key]);
            }
        }
    }
    return formData;
}

/**
 * 将图片路径拼接 oss 地址
 */
export function ossImageUrl(imageUrl: Array<string> | string): any {
    if (imageUrl === null || imageUrl === undefined) {
        return [];
    }
    if (Array.isArray(imageUrl)) {
        const kl = imageUrl.map((item: string) => process.env.VUE_APP_PERSON_INFO_OSS_URL + item);
        return kl;
    } else {
        return process.env.VUE_APP_PERSON_INFO_OSS_URL + imageUrl;
    }
}

export const isVoid = (value: any) => {
    return value === null || value === undefined;
};

/**
 * 获取属性值
 */
export function getValueByProp(data: any, prop: string) {
    const defaultText = '--';
    let temp = data;
    const propArr = prop.split('.');
    let i = 0;
    while (i < propArr.length) {
        if (!temp) {
            return defaultText;
        }
        temp = temp[propArr[i]];
        i++;
    }
    return temp;
}

export function getByPath(data: any, prop: string, defaultValue: any = '--') {
    const value = _.get(data, prop);
    return _.isNil(value) ? defaultValue : value;
}

// 赋值目标对象已有的key的值
export const objAssign = (target: Object, source: Object) => {
    const ownKeys: string[] = Object.keys(target).filter(key => Object.prototype.hasOwnProperty.call(target, key));
    ownKeys.forEach(key => {
        // @ts-ignore
        target[key] = source[key];
    });
    return target;
};

export function getDefaultValue(value: any, defaultValue: any) {
    return _.isNil(value) ? defaultValue : value;
}

export const getCompleteUrl = (url: string) => {
    return url && process.env.VUE_APP_OSS_URL + url;
};

// eslint-disable-next-line no-use-before-define
export const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;
