import axios from 'axios';
import { Message } from 'element-ui';

export const passportClient = axios.create({
    baseURL: process.env.VUE_APP_PASSPORT_BASE_URL, // url = base url + request url
    timeout: 10000 // request timeout
});

const passportClientErrorResponseHandler = (error: any) => {
    if (error.message === 'Network Error' || (error.message && error.message.includes('timeout'))) {
        Promise.reject(new Error('服务器请求超时，请检查网络连接'));
        Message.error('服务器请求超时，请检查网络连接');
    }
    if (error.response.status === 401 && error.response.config.url === '/home/auto') {
        // Message.error('用户未授权，请联系管理员');
    }
};

// request interceptor
passportClient.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        // do something with request error
        return Promise.reject(error);
    }
);

passportClient.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        passportClientErrorResponseHandler(error);
        return Promise.reject(error.response.message);
    }
);

export default passportClient;
