/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'email': {
    width: 45,
    height: 36,
    viewBox: '0 0 45 36',
    data: '<path pid="0" d="M6.137 36A6.077 6.077 0 010 30V8.022 6a6.077 6.077 0 016.137-6h32.726A6.077 6.077 0 0145 6v24a6.077 6.077 0 01-6.137 6zM4.09 30a2.026 2.026 0 002.046 2h32.727a2.025 2.025 0 002.045-2V11.841L26.019 22.033a6.272 6.272 0 01-7.039 0L4.09 11.841zm17.237-11.245a2.091 2.091 0 002.346 0l17.235-11.8V6a2.025 2.025 0 00-2.045-2H6.137A2.026 2.026 0 004.09 6v.958z" _fill="#fff"/>'
  }
})
