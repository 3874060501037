import { Form } from 'element-ui';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

@Component({ name: 'ListHeader' })
export default class ListHeader extends Vue {
    // 表单
    @Ref('conditionsForm') public form!: Form;

    // 标题
    @Prop({ required: false }) private readonly title!: string;
    // 是否展示搜索
    @Prop({ default: true }) private readonly search!: boolean;
    // 搜索条件模型
    @Prop() private conditionModel: any;
    @Prop({ default: 80 }) private labelWidth!: number;

    // 重置表单
    public resetForm() {
        this.form.resetFields();
    }
}
