import { Component, Prop, Vue } from 'vue-property-decorator';

import { getDoubleDigit } from '@/commons/utils/es/string.utils';

@Component
export default class formPageHeader extends Vue {
    @Prop({ required: false }) readonly title!: string;
    /**
     * 展示编号
     */
    @Prop({ required: false }) readonly number!: string;
    /**
     * 返回
     * @private
     */
    @Prop({ required: false }) private beforeBack!: Function | undefined;

    private get headerTitle(): string {
        return this.title || this.$route.meta.title;
    }

    /**
     * 个位数补零
     */
    get getDigit() {
        return getDoubleDigit(this.number);
    }

    get getDigits() {
        return getDoubleDigit(this.number);
    }

    public async back() {
        if (!this.beforeBack) {
            this.$router.go(-1);
        } else {
            const backable: boolean = await this.beforeBack() as unknown as boolean;
            if (backable) {
                this.$router.go(-1);
            }
        }
    }
}
