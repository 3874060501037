import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 *  col
 */
@Component({ name: 'Col' })
export default class extends Vue {
    @Prop({
        required: false,
        default: 24
    })
    private xs!: number;

    @Prop({
        required: false,
        default: 12
    })
    private sm!: number;

    @Prop({
        required: false,
        default: 8
    })
    private md!: number;

    @Prop({
        required: false,
        default: 6
    })
    private lg!: number;
}
