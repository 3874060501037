import { Form } from 'element-ui';
import { ElRow } from 'element-ui/types/row';
import _ from 'lodash';
import { Component, Prop, Provide, Ref, Vue, Watch } from 'vue-property-decorator';

@Component({ name: 'ListHeader' })
export default class ListHeader extends Vue {
    // 是否展开
    public expansion: boolean = false;
    // 表单
    @Ref('conditionsForm') public form!: Form;
    @Ref('conditionsFormRow') public formRow!: ElRow;

    // 标题
    @Prop({ required: false }) private readonly title!: string;
    // 是否展示搜索
    @Prop({ default: true }) private readonly search!: boolean;
    // 搜索条件模型
    @Prop() private conditionModel: any;
    @Prop({ default: 80 }) private labelWidth!: number;

    // 屏幕宽度
    public screenWidth: number | null = null;

    public conditionFormClassName: string = '';

    // 设置展开状态
    @Provide()
    public setExpansion(flag: boolean) {
        this.expansion = flag;
    }

    // 重置表单
    public resetForm() {
        this.form.resetFields();
    }

    // 计算表单项数量
    @Watch('expansion')
    @Watch('screenWidth')
    public getConditionFormItemQuantity() {
        if (!this.screenWidth) return false;
        if (this.screenWidth < 768) {
            this.conditionFormClassName = 'condition-form-xs';
        } else if (this.screenWidth < 992) {
            this.conditionFormClassName = 'condition-form-sm';
        } else if (this.screenWidth < 1200) {
            this.conditionFormClassName = 'condition-form-md';
        } else {
            this.conditionFormClassName = 'condition-form-lg';
        }
    }

    public created() {
        this.$nextTick(() => {
            if (this.$parent.$el.clientWidth) this.screenWidth = this.$parent.$el.clientWidth;
            this.getConditionFormItemQuantity();
            const _this = this;
            window.self.addEventListener('resize', _.debounce(() => {
                if (this.$parent.$el.clientWidth) _this.screenWidth = this.$parent.$el.clientWidth;
            }, 50));
        });
    }
}
