/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'zhpt-two': {
    width: 32,
    height: 44,
    viewBox: '0 0 32 44',
    data: '<path pid="0" d="M6 44a6.007 6.007 0 01-6-6V6a6.007 6.007 0 016-6h20a6.007 6.007 0 016 6v32a6.007 6.007 0 01-6 6zM4 6v32a2 2 0 002 2h20a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 01-2 2H10a2 2 0 01-2-2H6a2 2 0 00-2 2zm10 22a2 2 0 112 2 2 2 0 01-2-2zm4.829-2.829a4.008 4.008 0 00-5.658 0 2 2 0 11-2.828-2.829 8.012 8.012 0 0111.314 0 2 2 0 11-2.828 2.829zM7.515 20.344a2 2 0 010-2.829 12 12 0 0116.97 0 2 2 0 01-2.829 2.829 8 8 0 00-11.314 0 2 2 0 01-2.828 0z" _fill="#36c7b5"/>'
  }
})
