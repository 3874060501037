import _ from 'lodash';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { getToken, removeToken, setToken } from '@/commons/utils/storage/cookies';
import { UserAuth, UserLoginParams } from '@/entities/passport/passport';
import { USER_ALL_ROLE, USER_ROLE, USER_TYPE, USER_TYPES } from '@/meta/user';
import router, { resetRouter } from '@/router';
import passportService from '@/services/passport/passport.service';
import store from '@/store';
import { PermissionModule } from '@/store/modules/permission';

export interface IUserState {
    token: string
    permissions: string[]
    userAuth: UserAuth | null
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
    public token = getToken() || '';
    public cooperationName: string | null = null;
    public userAuth: UserAuth | null = null;
    public permissions: string[] = [];
    public permissionModule = PermissionModule;
    // 普通用户权限枚举
    public USER_TYPE = USER_TYPE;
    // 所有权限
    public USER_ALL_ROLE = USER_ALL_ROLE;

    @Mutation
    public SET_TOKEN(token: string) {
        this.token = token;
        setToken(token);
    }

    @Mutation
    private SET_PERMISSIONS(permissions: string[]) {
        this.permissions = permissions;
    }

    @Mutation
    private SET_USER_AUTH(userAuth: UserAuth) {
        this.userAuth = userAuth;
    }

    @Mutation
    private CLEAR_USER_AUTH() {
        this.userAuth = null;
    }
    @Mutation
    public SET_CooperationName(cooperationName: string | null) {
        this.cooperationName = cooperationName;
    }

    @Action({ rawError: true })
    public async Login(userLoginParams: UserLoginParams) {
        userLoginParams.username.trim();
        const { data } = await passportService.login(userLoginParams);
        setToken(data.accessToken);
        this.SET_TOKEN(data.accessToken);
    }

    @Action({ rawError: true })
    public ResetToken() {
        removeToken();
        this.SET_TOKEN('');
        this.SET_PERMISSIONS([]);
    }

    @Action({ rawError: true })
    public async GetUserInfo() {
        // console.log(11);
        if (this.token === '') {
            throw Error('登录已过期，请重新登录');
        }
        const userAuth: UserAuth = await passportService.getUserAuth(this.token);
        // 右上角切换切换为管理员之后,菜单栏需要变更为管理员能查看的菜单
        if (USER_ROLE.ADMIN.match(userAuth.authorities[0])) {
            this.permissions.push('admin');
            this.permissions.push('BO');
            this.permissions.push('PROJECT');
            this.permissionModule.GenerateRoutes(this.permissions);
        } else {
            // 右上角切换为普通用户之后,查看权限清除,并且变更菜单栏
            this.permissions.splice(0, 1);
            this.permissionModule.GenerateRoutes(this.permissions);
            if (userAuth.authorities.includes(String(USER_ALL_ROLE.ROLE_BO_MANAGER.code))) {
                this.permissions.push('BO');
                this.permissionModule.GenerateRoutes(this.permissions);
            }
            if (userAuth.authorities.includes(String(USER_ALL_ROLE.ROLE_PROJECT_MANAGER.code))) {
                this.permissions.push('PROJECT');
                this.permissionModule.GenerateRoutes(this.permissions);
            }
            if (userAuth.authorities.includes(String(USER_ALL_ROLE.ROLE_NEWS_ALL.code)) && !userAuth.authorities.includes(String(USER_ALL_ROLE.ROLE_PROJECT_MANAGER.code)) && !userAuth.authorities.includes(String(USER_ALL_ROLE.ROLE_BO_MANAGER.code))) {
                this.permissions.push('NO_DATA');
                this.permissionModule.GenerateRoutes(this.permissions);
            }
        }
        this.SET_USER_AUTH(userAuth);
    }

    @Action({ rawError: true })
    public async LogOut() {
        this.SET_PERMISSIONS([]);
        resetRouter();
        PermissionModule.SET_GENERATED(false);
        if (this.token === '') {
            throw Error('LogOut: token is undefined!');
        }
        removeToken();
        this.SET_TOKEN('');
        await router.push({ name: 'login' }).catch(_.noop);
        setTimeout(this.CLEAR_USER_AUTH, 3000);
    }

    @Action({ rawError: true })
    public IsAdmin(): boolean {
        return !!(this.userAuth && USER_TYPES.ADMIN.match(this.userAuth.user.type));
    }
}

export const UserModule = getModule(User);
