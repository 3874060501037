var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "page-content" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _vm._t("grade", function () {
          return [
            _c("span", { staticClass: "grade" }, [_vm._v(_vm._s(_vm.grade))]),
          ]
        }),
        _c("span", { staticClass: "header__title" }, [
          _vm._v(" " + _vm._s(_vm.headerTitle) + " "),
        ]),
        _vm._t("state"),
        _vm._t("title"),
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "content",
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }