var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            visible: true,
            "close-on-click-modal": _vm.closeOnClickModal,
            title: _vm.title,
            width: _vm.$attrs.width || "424px",
            "append-to-body": "",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function () {
                  return [_vm._t("title")]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [_vm._t("footer")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }