// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.info-item {\n  width: 100%;\n  margin-bottom: 24px;\n  line-height: 22px;\n  color: rgba(0, 0, 0, 0.85);\n  white-space: nowrap;\n  font-size: 14px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.info-item__value {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  white-space: normal;\n  overflow-wrap: break-word;\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.65);\n  word-break: break-all;\n}\n.info-item__value.error-dot {\n  color: #FF4D4F;\n}\n.info-item__value.warning-dot {\n  color: #FD9728;\n}\n.info-item__value.success-dot {\n  color: #3EAB00;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "white",
	"menuText": "rgba(0, 0, 0, 0.65)",
	"menuActiveText": "#005BB5"
};
module.exports = exports;
