import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * 页面底部
 */
@Component({ name: 'PageFooter' })
export default class extends Vue {
    @Prop({ default: false }) public loading!: boolean;
    @Prop({ default: true }) public submitable!: boolean;
}
