/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sbgl': {
    width: 44,
    height: 44,
    viewBox: '0 0 44 44',
    data: '<path pid="0" d="M36 44H2a2 2 0 010-4h4V6a6.007 6.007 0 016-6h20a6.007 6.007 0 016 6v34h4a2 2 0 010 4zm-2-4V6a2 2 0 00-2-2H12a2 2 0 00-2 2v34zm-12.743-6.143a2 2 0 01-1.114-2.6L23.046 24H18a2 2 0 01-1.857-2.743l4-10a2 2 0 013.714 1.486L20.954 20H26a2 2 0 011.857 2.743l-4 10a2 2 0 01-2.6 1.114z" _fill="#36c7b5"/>'
  }
})
