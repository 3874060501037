/**
 * 自定义vue指令
 */

import lodash from 'lodash';
import Vue from 'vue';
import { DirectiveOptions } from 'vue/types/options';
// @ts-ignore
import ClickOutside from 'vue-click-outside';
import Sticky from 'vue-sticky-directive';

// import disabledButton from './disabled';
// @ts-ignore
import permissionDirective from './permission';
// @ts-ignore
import NSticky from './sticky.js';

Vue.use(Sticky);

const autoInputWidth: DirectiveOptions = {
    inserted(el) {
        const inputElement = el.querySelector<HTMLElement>('.el-input__inner')!;
        inputElement.style.transition = 'min-width 300ms';
    },
    update(el, binding, node) {
        const inputElement = el.querySelector<HTMLElement>('.el-input__inner')!;
        const value: string = lodash.get(binding, 'value');
        if (lodash.isNil(value)) {
            inputElement.style.minWidth = `${28}px`;
            inputElement.style.maxWidth = `${28}px`;
        } else {
            inputElement.style.minWidth = `${42}px`;
            inputElement.style.maxWidth = `${42}px`;
        }
    }
};

Vue.directive('permission', permissionDirective);
Vue.directive('clickoutside', ClickOutside);
// Vue.directive('disabledButton', disabledButton);
Vue.directive('autoInputWidth', autoInputWidth);
Vue.directive('autoInputWidth', autoInputWidth);
Vue.directive('NSticky', NSticky);
