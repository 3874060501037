/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dtxs': {
    width: 44,
    height: 44,
    viewBox: '0 0 44 44',
    data: '<path pid="0" d="M2 44a2 2 0 01-2-2V2a2 2 0 014 0v38h38a2 2 0 010 4zm7.188-12.172a2 2 0 01-1.016-2.64l8-18a2 2 0 013.242-.6L31.54 22.711l8.712-15.682a2 2 0 113.5 1.942l-10 18a2 2 0 01-3.163.443l-11.94-11.94-6.821 15.338a2 2 0 01-2.64 1.015z" _fill="#36c7b5"/>'
  }
})
