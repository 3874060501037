var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "list-page-container" }, [
    _c("div", { staticClass: "list-page-header" }, [_vm._t("header")], 2),
    _c("div", { staticClass: "list-page-body" }, [
      _c("div", { staticClass: "scroll-panel" }, [_vm._t("body")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }