/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'yygl': {
    width: 44,
    height: 42,
    viewBox: '0 0 44 42',
    data: '<path pid="0" d="M30.545 39.846a18.028 18.028 0 01-17.09 0A8 8 0 114.24 26.94 18.14 18.14 0 014 24 18.01 18.01 0 0114 7.87a8 8 0 0116 0 18.029 18.029 0 019.76 19.07 8 8 0 11-9.216 12.906zM36 38a4 4 0 001.026-7.867l-.1-.024-.023-.006a4 4 0 00-4.29 6.028l.047.073A4 4 0 0036 38zm-14 0a13.945 13.945 0 006.388-1.538A8 8 0 0135.858 26 14.135 14.135 0 0036 24a13.939 13.939 0 00-7-12.131 8 8 0 01-14 0A13.921 13.921 0 008 24a14.141 14.141 0 00.142 2 8 8 0 017.47 10.46A13.943 13.943 0 0022 38zM4 34a4 4 0 104-4 4 4 0 00-4 4zm18-22a4 4 0 10-3.932-3.265l.006.031A4.006 4.006 0 0022 12z" _fill="#36c7b5"/>'
  }
})
