import { render, staticRenderFns } from "./dialog.vue?vue&type=template&id=18e6fb69&scoped=true&"
import script from "./dialog.ts?vue&type=script&lang=ts&"
export * from "./dialog.ts?vue&type=script&lang=ts&"
import style0 from "./style.scss?vue&type=style&index=0&id=18e6fb69&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18e6fb69",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\workspace\\qycc-website\\admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('18e6fb69')) {
      api.createRecord('18e6fb69', component.options)
    } else {
      api.reload('18e6fb69', component.options)
    }
    module.hot.accept("./dialog.vue?vue&type=template&id=18e6fb69&scoped=true&", function () {
      api.rerender('18e6fb69', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/commons/components/cc/dialog/dialog.vue"
export default component.exports