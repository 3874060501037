/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'top': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M10.182 28a1.272 1.272 0 01-1.273-1.273v-8.909H3.818a1.273 1.273 0 01-.9-2.172L13.1 5.464a1.273 1.273 0 011.8 0l10.182 10.182a1.273 1.273 0 01-.9 2.172h-5.091v8.909A1.273 1.273 0 0117.818 28zM1.273 2.545a1.273 1.273 0 110-2.545h25.454a1.273 1.273 0 010 2.545z" _fill="#36c7b5"/>'
  }
})
