import { Table as ElTable } from 'element-ui';

export default {
    extends: ElTable,
    methods: {
        // @ts-ignore
        doLayout(...args) {
            // @ts-ignore
            ElTable.methods.doLayout.call(this, ...args);
            this.fixLayout(); // Looks like we can do it once?
        },

        fixLayout() {
            // Safari header/content misalign fix (possible other browsers?)
            // @ts-ignore
            let csTag = this.__cst;
            if (!csTag) {
                csTag = this.__cst = document.createElement('style');
                this.$el.appendChild(csTag);
            }
            // @ts-ignore
            const colStyles = this.columns.map((c) => {
                // element-ui/packages/table/src/layout-observer.js:49
                const width = c.realWidth || c.width || c.minWidth;
                // `table.el-table__header th.${c.id}, table.el-table__body td.${c.id} { STYLES }`
                return `th.${c.id}, td.${c.id} { min-width: ${width}px; max-width: ${width}px }`;
            });
            csTag.textContent = colStyles.join('\n');
        }
    }
};
