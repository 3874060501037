/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'smile': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M12.215 38.427A20.058 20.058 0 1120 40a19.929 19.929 0 01-7.785-1.573zM3.636 20A16.364 16.364 0 1020 3.636 16.382 16.382 0 003.636 20zm9.624 8.118a1.818 1.818 0 112.571-2.572 5.9 5.9 0 008.338 0 1.818 1.818 0 112.571 2.572 9.532 9.532 0 01-13.48 0zM29.4 17.5a2.552 2.552 0 00-4.247 0 1.818 1.818 0 11-3.026-2.017 6.188 6.188 0 0110.3 0A1.818 1.818 0 1129.4 17.5zm-14.546 0a2.552 2.552 0 00-4.247 0 1.818 1.818 0 11-3.026-2.017 6.188 6.188 0 0110.3 0 1.818 1.818 0 11-3.03 2.017z" _fill="#fff"/>'
  }
})
