var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dialog-footer" },
    [
      _vm._t("prefix"),
      _c(
        "el-button",
        {
          staticClass: "ml-auto",
          attrs: { size: "mini" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" 取 消 ")]
      ),
      _c(
        "el-button",
        {
          attrs: {
            type: "primary",
            disabled: _vm.disabled,
            loading: _vm.loading,
            size: "mini",
          },
          on: {
            click: function ($event) {
              return _vm.$emit("submit")
            },
          },
        },
        [_vm._v(" 确 定 ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }