/**
 * 将对象转化为formData
 * @param obj
 */
import _ from 'lodash';

export function convertObjectToFormData(obj: any): FormData {
    const formData = new FormData();
    for (const key of Object.keys(obj)) {
        if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '' && obj[key].length !== 0) {
            if (Array.isArray(obj[key]) && Object.prototype.toString.call(obj[key][0])) {
                obj[key].forEach((value: any) => {
                    formData.append(key, value);
                });
            } else {
                formData.append(key, obj[key]);
            }
        }
    }
    return formData;
}

export function getDefaultValue(value: any, defaultValue: any) {
    return (_.isNil(value) || value === '') ? defaultValue : value;
}

// 获取属性值
export function getValueByProp(data: any, prop: string, defaultValue: any = '--') {
    let temp = data;
    const propArr = prop.split('.');
    let i = 0;
    while (i < propArr.length) {
        if (!_.isObjectLike(temp)) return getDefaultValue(temp, defaultValue);
        temp = temp[propArr[i]];
        i++;
    }
    return getDefaultValue(temp, defaultValue);
}
