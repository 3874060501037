// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.hover-wrap {\n  position: absolute;\n  background-color: #fff;\n  color: #666;\n  font-size: 14px;\n  line-height: 1.4;\n  padding: 6px 10px;\n  border-radius: 5px;\n  border: 1px solid #eee;\n  max-width: 400px;\n  -webkit-transition: opacity 0.3s linear;\n  transition: opacity 0.3s linear;\n  z-index: -1;\n  opacity: 0;\n}\n.hover-wrap.active {\n  z-index: 1999;\n  opacity: 1;\n  -webkit-transform: translateY(-100%);\n          transform: translateY(-100%);\n}\n.hover-wrap.active::after {\n  content: \"\";\n  display: inline-block;\n  -webkit-box-sizing: content-box;\n          box-sizing: content-box;\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 5px 5px 0 5px;\n  border-color: rgba(0, 0, 0, 0.85) transparent transparent transparent;\n  position: absolute;\n  left: 50%;\n  -webkit-transform: translateX(-50%);\n          transform: translateX(-50%);\n  bottom: -5px;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "white",
	"menuText": "rgba(0, 0, 0, 0.65)",
	"menuActiveText": "#005BB5"
};
module.exports = exports;
