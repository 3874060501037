import 'normalize.css';
import '@/styles/element-variables.scss';
import '@/styles/index.scss';
import '@/styles/local.element.ui/index.scss';
import '@/commons/components';
import '@/icons/components';
import '@/router/permission';
import '@/commons/filters/register.filters';
import '@/commons/directives';

// import '@/commons/components/element';
import ElementUI from 'element-ui';
import Vue from 'vue';
// 注册路由钩子函数
import { Component } from 'vue-property-decorator';
import SvgIcon from 'vue-svgicon';

import App from '@/App.vue';
import { getByPath } from '@/commons/utils/object';
import utils from '@/commons/utils/prototype';
import router from '@/router';
import store from '@/store';

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);
Vue.prototype.$utils = utils;
Vue.prototype.$get = getByPath;

Vue.use(ElementUI);
Vue.use(SvgIcon, {
    tagName: 'svg-icon',
    defaultWidth: '1em',
    defaultHeight: '1em'
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app');
