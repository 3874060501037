import UltMeta, { Meta } from 'ult-meta';

// 禁用状态
class AccountState extends UltMeta {
    ACTIVE = new Meta(1, '已启用');
    INACTIVE = new Meta(21, '未激活');
    FROZEN = new Meta(91, '已冻结');
}

export const USER_STATES = new AccountState();

// 用户类型
class UserTypes extends UltMeta {
    ADMIN = new Meta(1, '管理员');
    NORMAL = new Meta(2, '普通用户');
}

export const USER_TYPES = new UserTypes();
// 用户角色
class UserRole extends UltMeta {
    ADMIN = new Meta('ROLE_1', '管理员');
    NORMAL = new Meta('ROLE_2', '普通用户');
}

export const USER_ROLE = new UserRole();
// 用户角色
class UserAllRole extends UltMeta {
    ADMIN = new Meta('ROLE_1', '管理员');
    NORMAL = new Meta('ROLE_2', '普通用户');
    ROLE_BO_MANAGER = new Meta('ROLE_BO_MANAGER', '商机');
    ROLE_PROJECT_MANAGER = new Meta('ROLE_PROJECT_MANAGER', '项目');
    ROLE_NEWS_ALL = new Meta('ROLE_NEWS_ALL', '新闻');
}

export const USER_ALL_ROLE = new UserAllRole();
// 用户角色
class UserType extends UltMeta {
    BO = new Meta(1, '商机管理', '(勾选后拥有客户管理和商机管理的查看和操作权限，但只可查看和操作与自己有关的资源)');
    PROJECT = new Meta(2, '项目管理', '(勾选后拥有客户管理和项目管理的查看和操作权限，但只可查看和操作与自己有关的资源)');
    NEWS = new Meta(3, '全新闻可见', '(勾选后拥有所有新闻的查看权限，不受客户资源的限制)');
}

export const USER_TYPE = new UserType();
// 用户角色
class UserTypeText extends UltMeta {
    BO = new Meta(1, ' (拥有客户管理和商机管理的查看和操作权限，但只可查看和操作与自己有关的资源)');
    PROJECT = new Meta(2, ' (拥有客户管理和项目管理的查看和操作权限，但只可查看和操作与自己有关的资源)');
    NEWS = new Meta(3, ' (拥有所有新闻的查看权限，不受客户资源的限制)');
}

export const USER_TYPE_TEXT = new UserTypeText();
// 用户性别
class GenderType extends UltMeta {
    WOMAN = new Meta(0, '女');
    MAN = new Meta(1, '男');
}

export const GENDER_TYPES = new GenderType();
// 当前路由停留页面
class Router extends UltMeta {
    CUSTOMER = new Meta(1, 'customerList');
    ACTIVITY = new Meta(2, 'activityList');
    CUSTOMER_MEETING = new Meta(3, 'conferenceList');
    BO = new Meta(4, 'opportunityList');
    BO_TASK = new Meta(5, 'boTaskList');
}

export const ROUTER = new Router();
