/**
 * 自定义的vue过滤器
 */

import Vue from 'vue';

import { getValueByProp } from '@/commons/utils/es/object';

import {
    defaultValue,
    getImageUrl,
    getTextByCode,
    mode,
    parseTime,
    parseTimeToYearMonth,
    parseWeek,
    percentage,
    title
} from './filters';

Vue.filter('parseTime', parseTime);
Vue.filter('getImageUrl', getImageUrl);
Vue.filter('mode', mode);
Vue.filter('percentage', percentage);
Vue.filter('getValueByProp', getValueByProp);
Vue.filter('getTextByCode', getTextByCode);
Vue.filter('title', title);
Vue.filter('parseTimeToYearMonth', parseTimeToYearMonth);
Vue.filter('parseWeek', parseWeek);
Vue.filter('defaultValue', defaultValue);
