var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    { staticClass: "table-btn", class: { disabled: _vm.disabled } },
    [
      _c(
        "el-tooltip",
        {
          ref: "elTooltip",
          staticClass: "item",
          attrs: {
            enterable: false,
            effect: "dark",
            content: _vm.content,
            placement: "top",
          },
        },
        [
          _c("svg-icon", {
            staticClass: "outline_no",
            class: { disabled: _vm.disabled },
            attrs: { name: _vm.icon, original: "" },
            on: { click: _vm.clickHandler },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }