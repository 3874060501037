/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'zhpt-three': {
    width: 36,
    height: 44,
    viewBox: '0 0 36 44',
    data: '<path pid="0" d="M16.624 43.452a105.7 105.7 0 01-8.173-8.873 58.7 58.7 0 01-5.693-8.12C.9 23.164 0 20.4 0 18A17.995 17.995 0 0130.728 5.272 17.951 17.951 0 0136 18c0 2.4-.9 5.164-2.758 8.459a58.7 58.7 0 01-5.693 8.12 105.694 105.694 0 01-8.173 8.873 2 2 0 01-2.752 0zM4 18c0 3.1 2.681 8.091 7.549 14.048 2.474 3.028 4.964 5.64 6.451 7.142 1.493-1.509 4-4.137 6.477-7.174C29.328 26.074 32 21.1 32 18a14 14 0 00-28 0zm6 0a8 8 0 118 8 8.01 8.01 0 01-8-8zm4 0a4 4 0 104-4 4 4 0 00-4 4z" _fill="#36c7b5"/>'
  }
})
