// 列表操作栏宽度 / 根据操作栏按钮个数决定
enum OperationCellWidthConstant {
    button2 = '110px',
    button3 = '148px',
    button4 = '184px',
    button5 = '228px',
    button6 = '272px',
}

export default OperationCellWidthConstant;
