var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "header" },
    [
      _c(
        "el-button",
        {
          attrs: { icon: "el-icon-arrow-left", type: "primary" },
          on: {
            click: function ($event) {
              return _vm.back()
            },
          },
        },
        [_vm._v(" 返回 ")]
      ),
      _c("span", { staticClass: "line" }),
      _c(
        "span",
        { staticClass: "header-title" },
        [
          _vm._t("title", function () {
            return [_vm._v(" " + _vm._s(_vm.headerTitle) + " ")]
          }),
        ],
        2
      ),
      this.$route.name === "visitorApplicationInfo"
        ? _c("el-tag", { staticClass: "showNumber", attrs: { size: "mini" } }, [
            _vm._v(" 单号：" + _vm._s(_vm.getDigit) + " "),
          ])
        : _vm.number
        ? _c("el-tag", { staticClass: "showParkID", attrs: { size: "mini" } }, [
            _vm._v(" 编号：" + _vm._s(_vm.getDigit) + " "),
          ])
        : _c("el-tag", { staticStyle: { display: "none" } }),
      _vm._t("state"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }