/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sjtb': {
    width: 44,
    height: 44,
    viewBox: '0 0 44 44',
    data: '<path pid="0" d="M6 44a6.007 6.007 0 01-6-6V6a6.007 6.007 0 016-6h32a6.007 6.007 0 016 6v32a6.007 6.007 0 01-6 6zM4 6v32a2 2 0 002 2h32a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2zm24 28V14.828l-4.586 4.585a2 2 0 11-2.829-2.828l8-8A2 2 0 0132 10v24a2 2 0 01-4 0zm-14.766 1.848A2 2 0 0112 34V10a2 2 0 014 0v19.172l4.586-4.586a2 2 0 012.829 2.828l-8 8a2 2 0 01-2.18.434z" _fill="#36c7b5"/>'
  }
})
