var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "page-header" },
    [
      _vm._t("before-title"),
      _c("span", { staticClass: "page-header__title" }, [
        _vm._v(" " + _vm._s(_vm.headerTitle) + " "),
      ]),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }