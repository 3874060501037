var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "form-page scroll-panel" }, [
    _c("div", { staticClass: "form-page__form" }, [_vm._t("default")], 2),
    _vm.buttonVisible
      ? _c(
          "div",
          { staticClass: "form-page__footer" },
          [
            _c(
              "el-button",
              {
                staticClass: "go-back-button",
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              },
              [_vm._v(" 返回 ")]
            ),
            _vm._t("footer"),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }