var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "text",
      staticClass: "vue-text",
      style: _vm.textStyle,
      on: { mouseenter: _vm.mouseenter, mouseleave: _vm.mouseleave },
    },
    [[_vm._v(" " + _vm._s(_vm.value) + " ")]],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }