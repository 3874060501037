import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'Tips'
})
export default class extends Vue {
    /**
     * 内容
     */
    @Prop({ required: true, default: '' })
    public content!: String;

    /**
     * 宽度
     */
    @Prop({ required: false, default: 'auto' })
    public width!: string;
}
