var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    { staticClass: "tipe" },
    [
      _c(
        "el-popover",
        { attrs: { placement: "right", width: _vm.width, trigger: "hover" } },
        [
          _c("div", {
            staticClass: "tips-content",
            domProps: { innerHTML: _vm._s(_vm.content || "请填写内容") },
          }),
          _c(
            "span",
            {
              staticClass: "tips",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [_c("svg-icon", { attrs: { name: "mark" } })],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }