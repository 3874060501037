/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'zhpt-one': {
    width: 28,
    height: 44,
    viewBox: '0 0 28 44',
    data: '<path pid="0" d="M6 44a6.007 6.007 0 01-6-6V12a6.007 6.007 0 016-6h16a6.007 6.007 0 016 6v26a6.007 6.007 0 01-6 6zM4 12v26a2 2 0 002 2h16a2 2 0 002-2V12a2 2 0 00-2-2H6a2 2 0 00-2 2zm9.257 25.857a2 2 0 01-1.114-2.6l3.149-7.873-5.777-1.443a2 2 0 01-1.372-2.684l4-10a2 2 0 013.714 1.486l-3.149 7.872 5.777 1.444a2 2 0 011.372 2.683l-4 10a2 2 0 01-2.6 1.114zM10 4a2 2 0 010-4h8a2 2 0 010 4z" _fill="#36c7b5"/>'
  }
})
