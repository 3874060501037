var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "page-footer" },
    [
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              return _vm.$router.go(-1)
            },
          },
        },
        [_vm._v(" 返回 ")]
      ),
      _vm.submitable
        ? _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("submit")
                },
              },
            },
            [_vm._v(" 提交 ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }