import 'nprogress/nprogress.css';

import NProgress from 'nprogress';
import { Route } from 'vue-router';

import settings from '@/settings';

import router from './index';

NProgress.configure({ showSpinner: false });

const whiteList = ['/home'];

router.beforeEach(async(to: Route, _: Route, next: any) => {
    NProgress.start();
    next();
});

router.afterEach((to: Route) => {
    // Finish progress bar
    NProgress.done();
    // set page title
    document.title = settings.title + (to.meta?.title ? ` - ${to.meta.title}` : '');
});
