/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cwgl': {
    width: 44,
    height: 36,
    viewBox: '0 0 44 36',
    data: '<path pid="0" d="M6 36a6.007 6.007 0 01-6-6V6a6.007 6.007 0 016-6h32a6.007 6.007 0 016 6v24a6.007 6.007 0 01-6 6zM4 6v24a2 2 0 002 2h32a2 2 0 002-2v-4H28a8 8 0 110-16h12V6a2 2 0 00-2-2H6a2 2 0 00-2 2zm20 12a4 4 0 004 4h12v-8H28a4 4 0 00-4 4zm2 0a2 2 0 112 2 2 2 0 01-2-2z" _fill="#36c7b5"/>'
  }
})
