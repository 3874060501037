import { Component, Prop, Vue } from 'vue-property-decorator';

import PageHeader from './page.header/page.header.vue';

/**
 * 页面
 */
@Component({ name: 'Page', components: { PageHeader } })
export default class extends Vue {
    @Prop({ default: false }) public loading!: boolean;
}
