import EXIF from 'exif-js/exif';
import { compressAccurately } from 'image-conversion';
import { Vue } from 'vue-property-decorator';

/**
 * 清除提交表单的空字符串
 * @param form
 */
export const getNotEmptyObject = (form: any) => {
    const obj = { ...form };
    Object.keys(obj).forEach(key => {
        if (!obj[key] && obj[key] !== 0) {
            delete obj[key];
        }
    });
    return obj;
};
/**
 * 清除提交表单的null/undefined
 * @param form
 */
export const clearUndefinedOrNull = (form: any) => {
    Object.keys(form).forEach(key => {
        if (form[key] === undefined || form[key] === null) {
            form[key] = '';
        }
    });
};

/**
 * 获取手机版本号
 */
export const getClientVersion = (): string | undefined => {
    const userAgent: string = navigator.userAgent.toLowerCase();
    const isIOS = userAgent.match(/cpu iphone os (.*?) like mac os/);
    return isIOS ? isIOS[1].replace(/_/g, '.') : undefined;
};

/**
 * 是否需要修正图片的角度
 */
export const needFixImageOrient = () => {
    const iosVersion = getClientVersion();
    if (!iosVersion) return true;
    const versions = iosVersion.split('.').map(item => Number(item));
    if (versions[0] < 13 || (versions[0] === 13 &&
        versions[1] < 5) || (versions[0] === 13 &&
        versions[1] === 5 && versions[2] < 1)) return true;
    return false;
};

/**
 * 压缩文件到制定大小
 */
export const compressImage = (image: File): Promise<Blob> => {
    return new Promise((resolve) => {
        const needFixImageOrientFlag: boolean = needFixImageOrient();
        if (!needFixImageOrientFlag) {
            compressAccurately(image, {
                size: 200
            }).then(compressed => resolve(compressed));
        } else {
            EXIF.getData(image as unknown as string, async function() {
                // 获取图片旋转标志位
                const orientation = EXIF.getTag(this, 'Orientation');
                const rotateImage = await compressAccurately(image, {
                    size: 100,
                    orientation: orientation
                });
                resolve(rotateImage);
            });
        }
    });
};

// 四舍五入保留小数点后多少位
export const toFixed = (num: string | number, digit: number) => Math.round(Number(num) * (10 ** digit)) / (10 ** digit);

// 获取地址栏参数
export const getQueryString = (name: string) => {
    const reg = `(^|&)${name}=([^&]*)(&|$)`;
    const url = window.location.search.substr(1) || window.location.hash.split('?')[1];
    if (url) {
        const r = url.match(reg);
        if (r != null) {
            return decodeURIComponent(unescape(r[2]));
        }
    }
    return null;
};
/**
 * 获取图片的格式名
 */
export const getImageSuffix = (imageName: string): string => {
    const Suffix = imageName.split('.')[imageName.split('.').length - 1];
    return '.' + Suffix;
};
export const noop = () => {
};

// 将金额转换为中文大写金额
export const convertCurrency = (money: any) => {
    let n;
    let i;
    // 汉字的数字
    const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
    // 基本单位
    const cnIntRadice = ['', '拾', '佰', '仟'];
    // 对应整数部分扩展单位
    const cnIntUnits = ['', '万', '亿', '兆'];
    // 对应小数部分单位
    const cnDecUnits = ['角', '分', '毫', '厘'];
    // 整型完以后的单位
    const cnIntLast = '圆';
    // 最大处理的数字
    const maxNum = 999999999999999.9999;
    // 金额整数部分
    let integerNum;
    // 金额小数部分
    let decimalNum;
    // 输出的中文金额字符串
    let chineseStr = '';
    // 分离金额后用的数组，预定义
    let parts;
    if (money === '') {
        return '';
    }
    money = parseFloat(money);
    if (money >= maxNum) {
        // 超出最大处理数字
        return '';
    }
    if (money === 0) {
        chineseStr = cnNums[0] + cnIntLast;
        return chineseStr;
    }
    // 转换为字符串
    money = money.toString();
    if (money.indexOf('.') === -1) {
        integerNum = money;
        decimalNum = '';
    } else {
        parts = money.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
    }
    // 获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
        let zeroCount = 0;
        const IntLen = integerNum.length;
        for (i = 0; i < IntLen; i++) {
            n = integerNum.substr(i, 1);
            const p = IntLen - i - 1;
            const q = p / 4;
            const m = p % 4;
            if (n === '0') {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0];
                }
                // 归零
                zeroCount = 0;
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m === 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        chineseStr += cnIntLast;
    }
    // 小数部分
    if (decimalNum !== '') {
        const decLen = decimalNum.length;
        for (i = 0; i < decLen; i++) {
            n = decimalNum.substr(i, 1);
            if (n !== '0') {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (chineseStr === '') {
        chineseStr += cnNums[0] + cnIntLast;
    } else if (decimalNum === '') {
        return chineseStr;
    }
    return chineseStr;
};
export const isExternal = (path: string) => /^(https?:|mailto:|tel:)/.test(path);

/**
 * 获取插入的排序
 * @param preSort
 * @param nextSort
 */
export const getInsertSort = (preSort: number, nextSort: number): number => {
    const average: number = (preSort + nextSort) / 2;
    // 获取前面排序的小数位
    const decimalNum: number = getDecimalNum(preSort);
    // 保留相同的位数，不要四舍五入sort.dialog/sort.dialog.ts
    const sameDecimalNumAve: number = Math.floor(average * Math.pow(10, decimalNum)) * Math.pow(0.1, decimalNum);
    if (sameDecimalNumAve === preSort) {
        return average;
    } else {
        return Number(sameDecimalNumAve.toFixed(decimalNum));
    }
};

/**
 * 获取一个数字的小数位数
 */
export const getDecimalNum = (num: number): number => {
    const numStr: string = num.toString();
    const numArr = numStr.split('.');
    if (numArr.length === 2) {
        return numArr[1].length;
    } else {
        return 0;
    }
};

export class RouteUtils {
    static getRouteId(vm: Vue): number {
        return Number(vm.$route.params.id);
    }
}

// 下载文件
export function downloadFile(url: string) {
    if (!url) {
        // eslint-disable-next-line no-console
        return console.error(
            'The parameters passed in are not correct, the function does not work properly.'
        );
    }

    const a = document.createElement('a');
    a.href = url;
    a.target = '_black';

    if (document.all) {
        a.click();
    } else {
        // 兼容 Firfox
        const evt = document.createEvent('MouseEvents');
        evt.initEvent('click', true, true);
        a.dispatchEvent(evt);
    }
}
