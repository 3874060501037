import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * 列表页面
 */
@Component({ name: 'CcTable' })
export default class extends Vue {
    @Prop({ required: true })
    private tableData!: any[];
    /**
     * 表格标题
     */
    @Prop({ required: false }) private title!: string;
    /**
     * 请求数据中
     */
    @Prop({ required: true })
    private loading!: boolean;
    /**
     * 是否显示分割线
     */
    @Prop({ required: false, default: false })
    private divider!: any;
}
