import { Select } from 'element-ui';

export default {
    extends: Select,
    methods: {
        selectOption() {
            if (!this.visible) return;
            if (this.options[this.hoverIndex]) {
                this.handleOptionSelect(this.options[this.hoverIndex]);
            }
        },
        resetInputHeight() {
            if (this.collapseTags && !this.filterable) return;
            this.$nextTick(() => {
                if (!this.$refs.reference) return;
                const inputChildNodes = this.$refs.reference.$el.childNodes;
                // @ts-ignore
                const input = [].filter.call(inputChildNodes, item => item.tagName === 'INPUT')[0];
                const tags = this.$refs.tags;
                const tagsHeight = tags ? Math.round(tags.getBoundingClientRect().height) : 0;
                const sizeInMap = this.initialInputHeight || 32;
                // @ts-ignore
                input.style.height = this.selected.length === 0
                    ? sizeInMap + 'px'
                    : Math.max(
                        tags ? (tagsHeight + (tagsHeight > sizeInMap ? 6 : 0)) : 0,
                        sizeInMap
                    ) + 'px';
                if (this.visible && this.emptyText !== false) {
                    this.broadcast('ElSelectDropdown', 'updatePopper');
                }
            });
        }
    }
};
