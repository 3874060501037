import { AxiosBasicCredentials, AxiosInstance } from 'axios';

import passportClient from '@/clients/passport.client';
import { AdminAuthorize, UserAuth, UserLoginParams, WxwLoginForm } from '@/entities/passport/passport';
import { UserModule } from '@/store/modules/user';

export class PassportService {
    public path: string;
    public client: AxiosInstance;

    constructor(path: string, client: AxiosInstance) {
        this.path = path;
        this.client = client;
    }

    /**
     * 登录请求token
     * @param data
     * @param ROLE
     */
    async login(data: UserLoginParams): Promise<AdminAuthorize> {
        const res = await this.client({
            url: '/login',
            method: 'post',
            withCredentials: false,
            data: data
        });
        return res as unknown as AdminAuthorize;
    }
    // 切换右上角角色单独使用
    public roleLogin(ROLE:string) {
        return this.client({
            url: '/login/hand/over',
            method: 'get',
            withCredentials: false,
            params: { ROLE },
            headers: {
                Authorization: UserModule.token
            }
        });
    }
    public async autoLogin(code: string): Promise<any> {
        const res = await this.client.request({
            method: 'post',
            url: '/login/auto',
            data: {
                code,
                wxwAgentId: process.env.VUE_APP_WXW_AGENT_ID
            }
        });
        return res?.data?.accessToken;
    }
    /**
     * 获取用户的授权信息
     * @param token
     * @return Promise<UserInfo>
     */
    async getUserAuth(token: string): Promise<UserAuth> {
        const res = await this.client({
            url: '/user/self/info ',
            method: 'get',
            headers: {
                // Authorization: 'Bearer ' + token
                Authorization: token
            }
        });
        return res.data as unknown as Promise<UserAuth>;
    }
    // 登出
    authLogout() {
        return this.client({
            url: '/auth/logout',
            method: 'get',
            headers: {
                // Authorization: 'Bearer ' + UserModule.token
                Authorization: UserModule.token
            }
        });
    }
}

const passportService = new PassportService('', passportClient);
export default passportService;
